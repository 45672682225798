import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

import Header from '../../../components/header';
import Footer from '../../../components/footer';
import Sidebar from '../../../components/sidebar';
import './../../../styles/ticket-transcript.css';

const TicketTranscripts = () => {
    const { teamName } = useParams();
    const navigate = useNavigate();
    const [isMember, setIsMember] = useState(false);
    const [loading, setLoading] = useState(true);
    const [teamInfo, setTeamInfo] = useState({});
    const [tickets, setTickets] = useState([]);
    const [sortOrder, setSortOrder] = useState('desc');
    const [filterStatus, setFilterStatus] = useState('all');
    const PUBLIC_IP = process.env.REACT_APP_PUBLIC_IP;

    // Fetch membership and transcripts
    const fetchTeamData = useCallback(async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        try {
            const response = await axios.get(`${PUBLIC_IP}/api/team/${encodeURIComponent(teamName)}/membership`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (response.data.isMember) {
                setIsMember(true);
                setTeamInfo({
                    id: response.data.teamId,
                    name: response.data.teamName || teamName,
                    logo: response.data.teamLogo || 'default-logo.png',
                });

                const ticketsResponse = await axios.get(`${PUBLIC_IP}/api/bot/${teamName}/tickets`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                setTickets(ticketsResponse.data.tickets);
            } else {
                setIsMember(false);
            }
        } catch (error) {
            console.error('Error fetching team data:', error);
            if (error.response?.status === 401) navigate('/login');
        } finally {
            setLoading(false);
        }
    }, [PUBLIC_IP, teamName, navigate]);

    useEffect(() => {
        fetchTeamData();
    }, [fetchTeamData]);

    const handleSortChange = (e) => {
        setSortOrder(e.target.value);
    };

    const handleFilterChange = (e) => {
        setFilterStatus(e.target.value);
    };

    const handleClaimTicket = async (ticketId) => {
        const token = localStorage.getItem('token');
        try {
            await axios.post(`${PUBLIC_IP}/api/tickets/${teamName}/${ticketId}/claim`, {}, {
                headers: { Authorization: `Bearer ${token}` },
            });
            fetchTeamData(); // Refresh the tickets list
        } catch (error) {
            console.error('Error claiming ticket:', error);
        }
    };

    const handleCloseTicket = async (ticketId) => {
        const token = localStorage.getItem('token');
        try {

            await axios.post(`${PUBLIC_IP}/api/tickets/${teamName}/${ticketId}/close`, {}, {
                headers: { Authorization: `Bearer ${token}` },
            });


            fetchTeamData(); // Refresh the tickets list
        } catch (error) {
            console.error('Error closing ticket:', error);
        }
    };

    const sortedAndFilteredTickets = tickets
        .filter(ticket => filterStatus === 'all' || ticket.status === filterStatus)
        .sort((a, b) => {
            if (sortOrder === 'asc') {
                return new Date(a.createdAt) - new Date(b.createdAt);
            } else {
                return new Date(b.createdAt) - new Date(a.createdAt);
            }
        });

    if (loading) return <p>Loading...</p>;
    if (!isMember) return <p>You are not a member of this team.</p>;

    return (
        <div className="layout">
            <Header />
            <Sidebar teamInfo={teamInfo} />
            <main className="main-content">
                <h1>{teamName} Ticket Transcripts</h1>
                <div className="controls">
                    <label>
                        Sort by:
                        <select value={sortOrder} onChange={handleSortChange}>
                            <option value="desc">Newest First</option>
                            <option value="asc">Oldest First</option>
                        </select>
                    </label>
                    <label>
                        Filter by status:
                        <select value={filterStatus} onChange={handleFilterChange}>
                            <option value="all">All</option>
                            <option value="open">Open</option>
                            <option value="claimed">Claimed</option>
                            <option value="closed">Closed</option>
                        </select>
                    </label>
                </div>
                {sortedAndFilteredTickets.length > 0 ? (
                    <div className="tickets-grid">
                        {sortedAndFilteredTickets.map((ticket) => (
                            <div key={ticket._id} className={`ticket-card ${ticket.status}`}>
                                <h3>{ticket.ticketName}</h3>
                                <p>Status: {ticket.status}</p>
                                <p>Created At: {new Date(ticket.createdAt).toLocaleString()}</p>
                                {ticket.closedAt && <p>Closed At: {new Date(ticket.closedAt).toLocaleString()}</p>}
                                <p>Creator: {ticket.creator}</p>
                                <p>Participants: {ticket.participants.join(', ')}</p>
                                <p>Category: {ticket.category}</p>
                                <p>Claimed by: {ticket.claimedBy || "N/A"}</p>
                                {ticket.chat_logs.length > 0 ? (
                                    <a href={`view/${ticket.ticketId}`} target="_blank" rel="noopener noreferrer" className="transcript-link">
                                        View Transcript
                                    </a>
                                ) : (
                                    <p>No transcript available</p>
                                )}
                                <div className='ticket-buttons'> 
                                    <button 
                                        className='claim-button' 
                                        onClick={() => handleClaimTicket(ticket._id)} 
                                        disabled={ticket.status === 'claimed' || ticket.status === 'closed'}
                                    >
                                        {ticket.status === 'claimed' ? 'Claimed' : 'Claim'}
                                    </button>
                                    <button 
                                        className='close-button' 
                                        onClick={() => handleCloseTicket(ticket._id)} 
                                        disabled={ticket.status === 'closed'}
                                    >
                                        {ticket.status === 'closed' ? 'Closed' : 'Close'}
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>No tickets available.</p>
                )}
            </main>
            <Footer />
        </div>
    );
};

export default TicketTranscripts;

