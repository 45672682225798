// Function to get the stored theme from localStorage
export const getStoredTheme = () => {
    return localStorage.getItem("theme") || 'light'; // Default to 'light' if no theme is stored
};

// Function to set the theme in localStorage and update the document's data-theme attribute
export const setTheme = (theme) => {
    localStorage.setItem("theme", theme);
    document.documentElement.setAttribute('data-theme', theme);
};

// Function to toggle between light and dark theme
export const toggleTheme = (currentTheme) => {
    const newTheme = currentTheme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    return newTheme;
};


// Function to check if the user has a specific permission
export function hasPermission(userRole, permissionScope) {
    if (userRole == "TEAM_OWNER") {return true}

    //check if the provided role has the required permission
}