import React from 'react';
import './../../styles/style.css';
import './../../styles/userModal.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const UserModal = ({ token, onClose }) => {
    const PUBLIC_IP = process.env.REACT_APP_PUBLIC_IP;
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await axios.post(`${PUBLIC_IP}/logout`, {}, {
                headers: { Authorization: `Bearer ${token}` },
            });
            localStorage.removeItem('token');
            navigate('/login');
            console.log('Logout successful');
        } catch (error) {
            console.error('Error logging out:', error);
            localStorage.removeItem('token');
            navigate('/login');
        }
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <button className="modal-close-btn" onClick={onClose}>×</button>
                <ul className="modal-options">
                    <li>
                        <a href="/profile">
                            <i class="fa-solid fa-user"></i> View Profile
                        </a>
                    </li>
                    <li>
                        <a href="/dashboard">
                            <i class="fa-solid fa-tachometer-alt"></i> Dashboard
                        </a>
                    </li>
                    <li>
                        <a href="/settings">
                            <i class="fa-solid fa-cogs"></i> Settings
                        </a>
                    </li>
                    <li>
                        <a href="/subscriptions">
                            <i class="fa-solid fa-credit-card"></i> Manage Subscriptions
                        </a>
                    </li>
                    <li>
                        <a href="/help">
                            <i class="fa-solid fa-question-circle"></i> Help
                        </a>
                    </li>
                    <li>
                        <button className="logout-btn" onClick={handleLogout}>
                            <i class="fa-solid fa-arrow-right-from-bracket"></i> Logout
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default UserModal;
