import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './../styles/header.css'; // Ensure you update your CSS accordingly
import { getStoredTheme } from './helper'; // Helper to get initial theme
import WebsiteLogo from './../assets/svgs/logo-dark-text-white.svg';
import UserModal from './../components/Modals/UserModal'; // Import the UserModal component

const Header = ({ username, token }) => {
    const [theme, setTheme] = useState(getStoredTheme());
    const [isModalOpen, setModalOpen] = useState(false);

    // Persist the theme and apply it to the document
    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
        localStorage.setItem('theme', theme);
    }, [theme]);

    // Toggle theme between light and dark
    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
    };

    // Toggle user modal visibility
    const handleModalToggle = () => {
        setModalOpen(!isModalOpen);
    };

    return (
        <header className="header">
            <div className="header-container">
                {/* Logo */}
                <Link to="/" className="logo">
                    <img src={WebsiteLogo} alt="Logo" className="header-logo" />
                </Link>

                {/* Spacer to push the remaining items to the right */}
                
                <div className="spacer"></div>

                <h1>[DEV]</h1>

                <div className="spacer"></div>

                {/* Theme Toggle Button */}
                <button onClick={toggleTheme} className="theme-toggle" aria-label="Toggle Theme">
                    {theme === 'dark' ? (
                        <i className="fa-solid fa-moon"></i>
                    ) : (
                        <i className="fa-solid fa-sun"></i>
                    )}
                </button>

                {/* Inbox Button */}
                <Link to="/inbox" className="inbox-button" aria-label="Inbox">
                    <i className="fa-regular fa-envelope"></i>
                </Link>

                {/* Username */}
                {username && <div className="username">{username}</div>}

                {/* Account Button */}
                <button
                    onClick={handleModalToggle}
                    className="account-button"
                    aria-label="Account"
                >
                    <i className="fa-regular fa-circle-user"></i>
                </button>

                {/* User Modal */}
                {isModalOpen && (
                    <UserModal
                        token={token}
                        onClose={handleModalToggle}
                        customStyle={{ top: '12%', right: '5%' }} // Optional inline style
                    />
                )}
            </div>
        </header>
    );
};

export default Header;
