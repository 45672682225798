import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './../styles/style.css';
import './../styles/profile.css';
import Header from '../components/header';
import Footer from '../components/footer';

const DISCORD_API_URL = process.env.DISCORD_API_URL || 'https://discord.com/api/v10';

const Profile = () => {
    const [userInfo, setUserInfo] = useState(null);
    const [discordUser, setDiscordUser] = useState(null);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const PUBLIC_IP = process.env.REACT_APP_PUBLIC_IP;

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const userProfilePromise = axios.get(`${PUBLIC_IP}/api/users/profile`, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                const userProfileResponse = await userProfilePromise;
                setUserInfo(userProfileResponse.data);

                if (userProfileResponse.data.discordConnected) {
                    const discordUserPromise = axios.get(`${DISCORD_API_URL}/users/@me`, {
                        headers: { Authorization: `Bearer ${userProfileResponse.data.discordConnected.accessToken}` }
                    });

                    const [discordUserResponse] = await Promise.all([discordUserPromise]);
                    setDiscordUser(discordUserResponse.data);
                }
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };

        fetchUserInfo();
    }, [token]);

    const handleDiscordConnect = () => {
        const token = localStorage.getItem('token'); // Retrieve the JWT token from local storage
        const redirectUri = `${PUBLIC_IP}/api/auth/discord/login?token=${token}`; // Include the token as a query parameter
        window.location.href = redirectUri;
    };

    const handleDiscordUnlink = async () => {
        try {
            await axios.post(`${PUBLIC_IP}/api/auth/discord/unlink`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setDiscordUser(null);
            setUserInfo(prevState => ({
                ...prevState,
                discordConnected: false
            }));
        } catch (error) {
            console.error('Error unlinking Discord:', error);
        }
    };

    return (
        <>
            <Header />
            <div className="container profile-container">
                <h2 className="page-title">Profile</h2>
                {userInfo ? (
                    <div className="profile-info">
                        <p><strong>Username:</strong> {userInfo.username}</p>
                        <p><strong>Email:</strong> {userInfo.email}</p>
                        <p><strong>Teams:</strong> {userInfo.teams.join(', ')}</p>
                        <div className="discord-connect">
                            <h3>Discord</h3>
                            {discordUser && (
                                <img
                                    src={`https://cdn.discordapp.com/avatars/${discordUser.id}/${discordUser.avatar}.png`}
                                    alt="Discord avatar"
                                    className="discord-avatar"
                                />
                            )}
                            <p><strong>Discord Username:</strong> {discordUser ? discordUser.username : 'Not Connected'}</p>
                            <p><strong>Discord:</strong> {userInfo.discordConnected ? 'Connected' : 'Not Connected'}</p>
                            {!userInfo.discordConnected && (
                                <button className="btn-primary" onClick={handleDiscordConnect}>
                                    Connect Discord
                                </button>
                            )}
                            {userInfo.discordConnected && (
                                <button className="btn-secondary" onClick={handleDiscordUnlink}>
                                    Unlink Discord
                                </button>
                            )}
                        </div>
                    </div>
                ) : (
                    <p>Loading profile...</p>
                )}
            </div>
            <Footer />
        </>
    );
};

export default Profile;