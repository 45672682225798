import React from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import './../styles/style.css';
import './../styles/auth.css';

const EmailConfirmed = () => {
    return (
        <>
            <Header />
            <div className='container'>
                <div className="confirmation-wrapper">
                    <h2>Email Confirmed</h2>
                    <p>Your email has been successfully confirmed. You can now log in to your account.</p>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default EmailConfirmed;
